import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/buildhome/repo/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "configure-stripe-payments"
    }}>{`Configure Stripe Payments`}</h1>
    <hr></hr>
    <p><em parentName="p">{`In order to properly configure Stripe Payments, you must have a Stripe account created. To learn how to do this, please refer to the `}<a parentName="em" {...{
          "href": "https://support.stripe.com/questions/getting-started-with-stripe-create-or-connect-an-account"
        }}>{`Stripe Account Creation`}</a>{` page for assistance.`}</em></p>
    <p>{`To configure Stripe Payments for your mobile app, follow these step-by-step instructions.:`}</p>
    <ol>
      <li parentName="ol">{`Access the Content Management System and navigate to the Mobile App section from the left side menu options. Click on the Mobile App where you want to configure payments for.`}</li>
      <li parentName="ol">{`Click on 'Edit' next to the Data Provider.`}</li>
      <li parentName="ol">{`Under the Payments section, toggle on 'Enabled'.`}</li>
      <li parentName="ol">{`Set the dropdown to 'Stripe Connect'. `}<em parentName="li">{`You can place Stripe Payments into test mode by selecting TEST MODE. Your app will go through the payment process by won't process the payment.`}</em></li>
      <li parentName="ol">{`To restrict access to the payment function during testing, list the barcodes of the testers in the 'Allowed users' field, separated by commas. After testing, you can remove the usernames to grant access to all patrons.`}</li>
      <li parentName="ol">{`Enter in your Stripe Connect ID. `}</li>
      <li parentName="ol">{`Toggle on 'Accept Individual Payments' if you want your patrons to bulk pay. `}<em parentName="li">{`This is not reccomended as Stripe charges a small fee for each transation.`}</em></li>
      <li parentName="ol">{`Click 'Save' at the bottom of the page. `}</li>
      <li parentName="ol">{`When directed back to the Mobile App screen, click 'Save' at the bottom to update your app. `}</li>
    </ol>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      